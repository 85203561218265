import React, {FC} from "react";
import Link from "next/link";
import {url} from "../../../services/utils";
import {IGlobalProduct} from "../../../types/state";
import cls from "../ProductCard/ProductCard.module.scss"

const ProductCardInfo: FC<{ product: IGlobalProduct }> = ({product}) => {
    const isFullArray = product?.product_flat?.length > 0
    const {product_flat} = product ?? {};
    const {
        name,
        short_description
    } = product_flat?.[0] ?? {};
    return (
        <div className={cls["product-card__info"]}>
            <div className={cls["product-card__name"]}>
                <Link
                    prefetch={false}
                    href={url.product(product)}
                >
                    <a>
                        {isFullArray && name || ""}
                    </a>
                </Link>
            </div>
            <div className={cls["product-card-description"]}>
                {isFullArray && short_description ? (<span
                    dangerouslySetInnerHTML={{
                        __html: short_description.replace(/<\/?[^>]+>/gi, ""),
                    }}
                />) : ("")}{" "}
            </div>
        </div>
    )
}
export default ProductCardInfo;
