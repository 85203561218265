import React, {FC} from "react";
import {setCrossValid, setTempData} from "../../../store/general";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../types/state";
import {ICrossButton as IProps} from "./types";
import Link from "next/link";
import cls from  "../ProductCard/ProductCard.module.scss"

const CrossButton: FC<IProps> = ({
                                     run,
                                     loading,
                                     product,
                                     openUpCrossProd,
                                     isProductInStock,
                                     temp,
                                     crossProd,
                                     addToast,
                                     toastAdd,
                                     tablet,
                                     load,
                                 }) => {
    const dispatch = useDispatch();
    const backorders = useSelector((state: IState) => state.general.Backorders);

    return isProductInStock(product) ? (
        <button
            type="button"
            onClick={(e) => {
                e.preventDefault()
                run()
                temp && dispatch(setTempData([product]))
                dispatch(setCrossValid(false))
                crossProd && openUpCrossProd(product)
                toastAdd && addToast && addToast(product)
            }}
            disabled={!backorders && !isProductInStock(product)}
            className={classNames(`
                btn btn-primary ${cls[`product-card__addToCart${tablet ? "-tablet" : ""}`]} ${tablet ? cls["show-for-tablet"] : cls["hide-for-tablet"]}
                 `,
                {
                    'btn-loading': !load ? loading : false,
                },)}
        >
            <FormattedMessage
                id="add_to_cart"
                defaultMessage="Add to cart"
            />
        </button>
    ) : (

        <>
            <Link href={`/${product.product_flat[0].url_key}`}>
                <button
                    className={classNames(`
                btn btn-primary ${cls[`product-card__addToCart${tablet ? "-tablet" : ""}`]} ${tablet ? cls["show-for-tablet"] : cls["hide-for-tablet"]}
                 `,
                        {
                            'btn-loading': !load ? loading : false,
                        },)}
                >

                    <FormattedMessage
                        id="add_to_cart"
                        defaultMessage="Add to cart"
                    />
                </button>
            </Link>
        </>
    )
}

export default CrossButton;
