import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import Link from "next/link";
import {useRouter} from "next/router";
import dynamic from 'next/dynamic'
import {setCrossValid, setPopupName, setWindowSize} from "../../store/general";
import {cartAddItem, getUpOrCrossProd} from "../../store/cart";
import CheckToastSvg from "-!svg-react-loader!../../svg/check.svg";
import {rightUrl, url} from "../../services/utils";
import defaultPicture from '../../images/defaultpic.webp'
import {useToast} from "../../atoms/Toast/useToast";
import {IGlobalProduct, IState} from "../../types/state";
import {IProductCart as IProps} from "./ProductCard/types";
import {containerClasses} from "./ProductCard/helpers";

import ProductCardImage from "./ProductCard/ProductCardImage";
import CrossButton from "./ProductCard/CrossButton";
import AddToCart from "./ProductCard/AddToCart";
import AuthList from "./ProductCard/AuthList";
import ProductCardInfo from "./ProductCard/ProductCardInfo";
import {domainUrl} from "../../helper";
import Image from "next/image";
import cls from "./ProductCard/ProductCard.module.scss"

// const ProductCardImage = dynamic(() => import("./ProductCard/ProductCardImage"))
// const CrossButton = dynamic(() => import("./ProductCard/CrossButton"))
// const AddToCart = dynamic(() => import("./ProductCard/AddToCart"))
// const AuthList = dynamic(() => import("./ProductCard/AuthList"))
// const ProductCardInfo = dynamic(() => import("./ProductCard/ProductCardInfo"))
const AsyncAction = dynamic(() => import('./AsyncAction'))

const ProductCard: FC<IProps> = (props: IProps) => {
    const {
        customer = false,
        product,
        layout,
        dbName
    } = props
    const locale = useSelector((state: IState) => state.locale.code);
    const rate = useSelector((state: IState) => state.rate.exchange_rate);
    const currency = useSelector((state: IState) => state.rate.current.code);
    const selectedData = useSelector((state: IState) => state.locale.code);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const backorders = useSelector((state: IState) => state.general.Backorders);
    const router = useRouter();
    const toast = useToast();
    const dispatch = useDispatch();
    const {product_flat} = product ?? {};
    const {
        name,
        has_up_sell,
        has_cross_sell,
    } = product_flat?.[0] ?? {};


    const isFullArray = product?.product_flat?.length > 0
    const addCart = isFullArray && has_up_sell == 0 && has_cross_sell == 0
    const onlyCross = isFullArray && has_up_sell == 0 && has_cross_sell == 1;
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    let windowSize = useSelector((state: IState) => state.general.windowSize)
    let diffProductImage = product
    && product.product_images.length > 0
        ? `${dbName}/storage/large/${product?.product_images[0].path}`
        : `${dbName}${defaultPicture.src}`

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 768)
        setIsTablet(windowSize < 1024)
    }, [windowSize])

    const addToast = () => {
        toast.open(
            <span className="d-flex chek-fms">
                <CheckToastSvg/>
                <FormattedMessage
                    id="added_to_cart_toast"
                    defaultMessage={`Product "${name}" added to cart`}
                />
            </span>
        );
    }

    const openUpCrossProd = (product: IGlobalProduct) => {
        let hasUpSell = has_up_sell == 1;
        let hasCrossSell = has_cross_sell == 1;
        let page = true;

        if (!hasUpSell && hasCrossSell) {
            dispatch(getUpOrCrossProd(product.id, 'crossSell', locale, currency, rate.rate, page))
            dispatch(setCrossValid(true));
            dispatch(setPopupName("crossSell"));
        }
        if (hasUpSell) {
            dispatch(setPopupName("upsell"));
            dispatch(getUpOrCrossProd(product.id, 'upSell', locale, currency, rate.rate, page))
        }
    };

    const isProductInStock = (product: { product_inventories: { qty: number }[]; }) => {
        let qty = product?.product_inventories[0].qty
        return Number(qty) > 0 || (Number(qty) === 0 && backorders)
    }

    const asyncAction = (
        toastAdd?: boolean,
        temp?: boolean,
        crossProd?: boolean,
        dispatching?: boolean,
        tablet?: boolean,
        load?: boolean
    ) => {
        const action = () => {
            if (!dispatching) {
                return dispatch(cartAddItem(product, [], 1, cartToken, customer, selectedData, null, 'homePage'));
            }
        };
        return (
            <AsyncAction
                action={action as () => ((dispatch: any) => Promise<any> | undefined) | undefined}
                render={({run: run, loading: loading}) => (
                    <CrossButton
                        run={run}
                        loading={loading}
                        product={product}
                        openUpCrossProd={openUpCrossProd}
                        isProductInStock={isProductInStock}
                        toastAdd={toastAdd}
                        temp={temp}
                        crossProd={crossProd}
                        tablet={tablet}
                        addToast={addToast}
                        load={load}
                    />)}
            />
        )
    }
    const handlerProductType = (tablet?: boolean) => {
        let actionParams: boolean[];

        switch (product?.type) {
            case 'configurable':
            case 'bundle':
                return (
                    <AddToCart
                        prefetch={false}
                        product={product}
                        tablet={!!tablet}
                        route={true}
                    />
                );
            default:
                if (addCart) {
                    actionParams = tablet ? [true, false, false, false, true] : [true];
                } else if (onlyCross) {
                    actionParams = tablet ? [true, true, true, false, true] : [false, true, true, false, false];
                } else {
                    actionParams = tablet ? [true, true, true, false, true] : [false, true, true, true, false, true];
                }
                break;
        }

        return asyncAction(...actionParams);
    };

    if (!product) {
        return null;
    }
    const productImage = product.product_images ? (

        <div className={`${cls["product-card__image"]}`}>
            <Link
                href={rightUrl(url.product(product), router)}
                prefetch={false}
                className={`${isTablet ? cls["product-card__image"] : ""}`}
            >
                <a>
                    {/*<div className="product-image__body product-image__body-fms">*/}
                    <div className={`${cls["product-image__body"]} ${cls["product-image__body-fms"]}`}>

                        {/*<div className={`${cls["item_overlay"]} ${cls["hide-for-tablet"]}`}/>*/}
                        {/*<div className={cls["img_btn_wrapper"]}>{handlerProductType()}</div>*/}
                        <Image
                            src={domainUrl(diffProductImage)}
                            alt="product cart image"
                            placeholder="blur"
                            layout="fill"
                            blurDataURL={`/zega/blur1.webp`}
                        />
                    </div>
                </a>
            </Link>
        </div>
    ) : (
        <ProductCardImage
            product={product}
            router={router}
            openUpCrossProd={openUpCrossProd}
            addToast={addToast}

        />
    );

    return (
        <>
            <div className={containerClasses(layout)}>
                {productImage}
                <ProductCardInfo product={product}/>
                {/*<div className={cls["product-card__actions"]}>*/}
                {/*    <AuthList product={product}/>*/}
                {/*    {handlerProductType(true)}*/}
                {/*</div>*/}
            </div>
        </>
    );
}


export default ProductCard;
